import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const ContentStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  padding: `${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(10)}`,
}));

const InfoRow = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "&:first-child": {
    marginBottom: theme.typography.pxToRem(14),
  },
  "&:last-child": {
    marginTop: theme.typography.pxToRem(5),
  },
}));

const InfoCol = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "noShrink",
})(({ theme, noShrink }) => ({
  flexShrink: noShrink ? 0 : 1,
  marginLeft: noShrink ? "15px" : 0,
}));

const Title = styled("p", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  color: theme.palette.text.main,
  // fontFamily: "GE-SS-Bold",
  marginBottom: theme.typography.pxToRem(8),
}));

const SubTitle = styled("p", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.text.main,
  // fontFamily: "GE-SS-Medium",
}));

const FeatureWrapper = styled("p", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
}));

const Divider = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  borderTop: `1px solid #EFEFEF`,
  marginBottom: '10px'


}));



const Price = styled("span", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: '14.7px', marginTop: '7px', fontWeight: '600'
}));
export { ContentStyled, InfoRow, InfoCol, Title, SubTitle, FeatureWrapper, Divider ,Price };
