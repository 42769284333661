import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { BsCheckLg } from "react-icons/bs";
import { AiFillTool } from "react-icons/ai";
import { Tb360View ,TbChecklist } from "react-icons/tb";

const FeaturesStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: `0 ${theme.typography.pxToRem(2)}`,
  display: "flex",
  "& .mui-style-ltr-153osmn-MuiTooltip-tooltip": {
    backgroundColor: "red !important",
  },
}));

const CheckIcon = styled(BsCheckLg, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
}));


// const Icon360 =  styled(Tb360View, {
//   shouldForwardProp: (prop) => isPropValid(prop),
// })(({ theme }) => ({
//   fontSize: theme.typography.pxToRem(12),
// }));


// const Icon360 = styled(TbChecklist, {
//   shouldForwardProp: (prop) => isPropValid(prop),
// })(({ theme }) => ({
//   fontSize: theme.typography.pxToRem(18),
// }));

const Icon360 = styled("img", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  maxWidth:'15px'
}));

const ServiceIcon = styled(TbChecklist, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
}));

const Feature = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme, active }) => ({
  width: "30px",
  height: "25px",
  borderRadius: "11px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.white.main,
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
  transition: "500ms all ease-in-out 0ms",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.brownGray.main,
  margin: `0 ${theme.typography.pxToRem(4)}`,
  "&:hover": {
    transform: "translateY(-2px) scale(1.1)",
  },
}));

export { FeaturesStyled, Feature, CheckIcon, ServiceIcon ,Icon360};