import React, { useState, useRef } from "react";
import Actions from "../Actions";
import {
  HeaderStyled,
  ListingType,
  PropertyType,
  SwiperWrapper,
} from "./style";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Lazy, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "swiper/css/lazy";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import * as _ from "lodash";
import { DialogContent, Typography } from "@mui/material";
import { CustomDialog } from "../Actions/style";
import ListingManagement from "../../../ListingManagement/ListingManagement";
import { Button } from "semantic-ui-react";
import { CustomButton } from "../../../common/Buttons/Buttons";
import TopHeaderOverlay from "../../components/TopHeaderOverlay";
import { combineStrings } from "../../../../utils/combineStrings";
import { useRouter } from "next/router";
import { PromotionOverlay } from "../Promotion/PromotionOverlay";

const Header = ({ data, listing, setOpen ,page=""}) => {
  const { t } = useTranslation("common");
  const floor = data?.floorNo == 0 ? t("ground_floor") : data?.floorNo;
  const swiperRef = useRef();
  const router = useRouter();

  const onInit = (Swiper) => {
    swiperRef.current = Swiper;
    if (swiperRef.current) swiperRef.current.autoplay.stop();
  };

  const handleMouseEnter = () => {
    if (swiperRef.current) swiperRef.current.autoplay.start();
  };
  const handleMouseLeave = () => {
    if (swiperRef.current) swiperRef.current.autoplay.stop();
  };
  const PropertyLink = ({ href, title, imageUrl, alt, page, media }) => {
    const router = useRouter();
  
    return (
      <PropertyLink
      href={
        data?.documentType == 0
          ?  router.locale === 'en' ? `/en/property-details/${propertySlug}-${data.advertisementNumber}` : `/property-details/${propertySlug}-${data.advertisementNumber}`
          : data?.documentType == 1
          ? router.locale === 'en' ? `/en/project/${data?.id}` :`/project/${data?.id}`
          :  router.locale === 'en' ?  `/en/project-model/${data?.id}` :`/project-model/${data?.id}` 
      }
      title={page === 'search' && data.title}
      imageUrl={!media?.imageSourceUrl ? media?.imageThumbUrl : imageUrl}
      alt={page === 'search' ? data.title : t("image")}
      page={page}
      media={media}
    />
    );
  };
// console.log('data==========' ,data);
  return (
    <HeaderStyled>
      <TopHeaderOverlay data={data} />
      <PromotionOverlay discount={data.discountPercentage}/>
      <SwiperWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Swiper
          onInit={onInit}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          lazy={true}
          modules={[Pagination, Lazy, Autoplay]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
        >
          {_.map(data?.media, (media, i) => {
            // 
            // Check if imageSourceUrl exist or not
            // not = imageThumbUrl
            // exist = imageSourceUrl

            // const parseImgSrc = media?.imageSourceUrl != undefined && media?.imageSourceUrl.replace(/^.*\/\/[^\/]+/, '');
            // const imageSourceUrl = `https://d33hstyes9suiv.cloudfront.net/fit-in/400x0/filters:strip_exif()${parseImgSrc}`;
            const imageUrl = media?.playbackId ? `https://image.mux.com/${media?.playbackId}/thumbnail.png?width=500&height=500&fit_mode=preserve` : media?.imageThumbUrl;
            
            const propertySlug = data.title.replace(/,?\s+/g, '-').toLowerCase();
            return (
              <SwiperSlide key={`property-thumb-slider-${media?.mediaPath}-${i}`}>
                <a
                  href={
                    data?.documentType == 0
                      ?  router.locale === 'en' ? `/en/property-details/${propertySlug}-${data.advertisementNumber}` : `/property-details/${propertySlug}-${data.advertisementNumber}`
                      : data?.documentType == 1
                      ? router.locale === 'en' ? `/en/project/${data?.id}` :`/project/${data?.id}`
                      :  router.locale === 'en' ?  `/en/project-model/${data?.id}` :`/project-model/${data?.id}` 
                  }
                  title={page == 'search' && data.title }
                >
                  <Image
                    // src={media?.imageThumbUrl}
                    src={imageUrl}
                    alt={page == 'search' ? data.title : t("image")}
                    title={page == 'search' ? data.title : t("image")}
                    fill
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    priority
                  />
                </a>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperWrapper>
      {!listing && <Actions data={data} />}
      {/* <PropertyType>
        <Typography
          variant="body3"
          size="smallest"
          sx={{ color: "white.main" }}
        >
          {data?.documentType == 0
            ? t(data?.propertyType)
            : data?.documentType == 1
            ? t("project")
            : t("project-flag", {
                property: data?.propertyType,
                title: data?.title,
                floor: floor,
              })}
        </Typography>
      </PropertyType> */}
      {listing && (
        <ListingType
          onClick={() => {
            setOpen(data?.id);
          }}
        >
          <Typography
            variant="body3"
            size="smallest"
            sx={{ color: "white.main" }}
          >
            {data?.listingStatusText}
          </Typography>
        </ListingType>
      )}
    </HeaderStyled>
  );
};

export default Header;
