import Box from "@mui/material/Box";
import chat from '../../../../../public/images/chat.svg';
import whatsapp from '../../../../../public/images/whatsapp2.svg';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Image from "next/image";
import { useAuth } from "../../../../context/auth";
import { useRouter } from "next/router";
import { event } from '../../../../utils/gtm'
const Chat = ({ id, setChatOpen, handleChat, data, isChatCreating }) => {

    const router = useRouter();
    const { t } = useTranslation("common");
    const [message, setMessage] = useState(t('WhatsApp_Message'));
    const { user } = useAuth();

    useEffect(() => {
    }, []);

    const handleWhatsApp = () => {

        let phone;
        const checkZero = data.data.sellerWhatsAppNumber.charAt(0);

        if (checkZero == "0") {
            phone = `966${data.data.sellerWhatsAppNumber.substr(1)}`
        } else {
            phone = `966${data.data.sellerWhatsAppNumber}`
        }
        window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`, '_blank');
        // if (user) {
        // const whatsappLink = `https://wa.me/${data.data.sellerWhatsAppNumber}/?text=${encodeURIComponent(message)}`;
        // window.open(whatsappLink, '_blank');
        // } else {
        //     router?.push({
        //         pathname: "/login",

        //     });
        // }

    };

    return (
        <Box sx={{ alignContent: "center", textAlign: "center" }}>
            <Typography
                variant="body1"
                size="Big"
                align="center"
                marginBottom="10px"
                fontWeight="bold"
            >
                {t("Chat_Option")}
            </Typography>
            <Typography variant="body1" align="center" marginBottom="20px">
                {t("Choose_your_preferred_chat_option")}
            </Typography>
            <hr style={{ color: "gray" }} />

            <IconButton
                aria-label="vertical contained button group"
                key="chat"
                onClick={() => {
                    event({
                        action: "chat_sanadak_selected",
                        params: {}
                    });
                    handleChat(data?.data?.sellerId, data?.data?.documentType)
                    isChatCreating && setChatOpen(false)
                }}
                sx={{ p: 0 }}
            >
                <Image src={chat} height={20} width={20} style={{ margin: "5px" }} />
                <Typography variant="body1" sx={{ color: "primary.main", fontSize: '20' }}>
                    {t("Sanadak_Chat")}
                </Typography>

            </IconButton>
            <hr />
            <IconButton
                aria-label="vertical contained button group"
                key="chat"
                onClick={() => {
                    event({
                        action: "chat_whatsapp_selected",
                        params: {}
                    });
                    handleWhatsApp()
                }}
                sx={{ p: 0 }}
            >
                <Image src={whatsapp} height={20} width={20} style={{ margin: "5px" }} />
                <Typography variant="body1" sx={{ color: "primary.main" }}>{t("WhatsApp")}
                </Typography>

            </IconButton>
            <hr sx={{ color: "gray" }} />
            <IconButton
                aria-label="vertical contained button group"
                key="chat"
                onClick={() => {
                    setChatOpen(false)
                }}
                sx={{ p: 0 }}
            >
                <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {t("cancel")}
                </Typography>
            </IconButton>

        </Box>
    );
};

export default Chat;
